import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

function usePreservedDiscountCode() {
	const searchParams = useSearchParams();
	const searchParamsDiscountCode = searchParams.get('discount');

	const [localStorageDiscountCode, setLocalStorageDiscountCode] = useLocalStorage(
		'discountCode',
		searchParamsDiscountCode
	);

	useEffect(() => {
		if (!searchParamsDiscountCode) return;

		setLocalStorageDiscountCode(searchParamsDiscountCode);
	}, [searchParamsDiscountCode]);

	return {
		discountCode: searchParamsDiscountCode || localStorageDiscountCode,
	};
}

export default usePreservedDiscountCode;
