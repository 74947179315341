'use client';

import {
	BtnColorEnum,
	BtnSizeEnum,
	BtnVariantEnum,
	type MaturaDownloader as MaturaDownloaderType,
} from '@features/api/client';
import { Button } from '@features/webshop/shared/ui/Button';
import { Checkbox } from '@features/webshop/shared/ui/Checkbox';
import { IconCheck, IconDownload, IconLoader2 } from '@tabler/icons-react';
import { isDefined } from 'remeda';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@features/webshop/shared/ui/Form';
import { cn } from '@features/shared/utils/utils';
import useGenerateMaturaDownloaderZip from './hooks/useGenerateMaturaDownloaderZip';
import normalizeProblemSourcesForDownloaderForm from './domain/normalizeProblemSourcesForDownloaderForm';
import { useEffect, useState } from 'react';
import MaturaDownloadItems from './MaturaDownloadItems';

type MaturaDownloaderFormProps = {
	data: MaturaDownloaderType;
};

export type MaturaDownloadItem = {
	title: string;
	status: 'pending' | 'success' | 'error' | 'not-started';
	downloadUrl?: string;
	terms: NonNullable<'ljeto' | 'jesen' | 'zima' | 'ogledna' | 'probna' | undefined>[];
	years: number[];
	subject: {
		id: number;
		level: 'A' | 'B' | null;
	};
};

const formSchema = z.object({
	subjects: z.array(z.string()).min(1, { message: 'Izaberi barem jedan predmet' }),
	years: z.array(z.string()).min(1, { message: 'Izaberi barem jednu godinu' }),
	terms: z.array(z.string()).min(1, { message: 'Izaberi barem jedan rok' }),
});

function MaturaDownloaderForm({ data }: MaturaDownloaderFormProps) {
	const [downloadItems, setDownloadItems] = useState<Array<MaturaDownloadItem>>([]);
	const form = useForm<z.infer<typeof formSchema>>({
		resolver: zodResolver(formSchema),
		mode: 'onSubmit',
		shouldFocusError: true,
		defaultValues: {
			subjects: [],
			years: [],
			terms: [],
		},
	});

	const { availableYears, availableTerms, availableSubjects, mandatorySubjects, optionalSubjects } =
		normalizeProblemSourcesForDownloaderForm({
			problemSources: data.matura_exam_problem_sources,
		});

	const areAllYearsSelected =
		form.watch('years').length > 0 &&
		form.watch('years').length === availableYears.filter((year) => year.enabled).length;
	const areAllTermsSelected =
		form.watch('terms').length > 0 &&
		form.watch('terms').length === availableTerms.filter((term) => term.enabled).length;
	const areAllTermsAndYearsSelected = areAllTermsSelected && areAllYearsSelected;

	const { mutate: generateZip, isPending } = useGenerateMaturaDownloaderZip();

	function onSubmit(values: z.infer<typeof formSchema>) {
		const years = values.years
			.map((year) => availableYears.find((availableYear) => availableYear.value === year)?.year)
			.filter(isDefined);
		const subjects = values.subjects
			.map((subject) => {
				const s = availableSubjects.find((availableSubject) => availableSubject.value === subject);

				if (!s) return undefined;

				return { id: s.subject_id, level: s.level || null, title: s.label };
			})
			.filter(isDefined);
		const terms = values.terms
			.map((term) => availableTerms.find((availableTerm) => availableTerm.value === term)?.term)
			.filter(isDefined);

		setDownloadItems(
			subjects.map((subject) => ({
				title: subject.title,
				status: 'not-started',
				terms,
				years,
				subject,
			}))
		);
	}

	useEffect(() => {
		const currentDownloadItem = downloadItems.find((item) => item.status === 'not-started');
		if (!currentDownloadItem) return;

		setDownloadItems(
			downloadItems.map((item) =>
				item.subject.id === currentDownloadItem.subject.id ? { ...item, status: 'pending' } : item
			)
		);

		generateZip(
			{
				years: currentDownloadItem.years,
				subjects: [currentDownloadItem.subject],
				terms: currentDownloadItem.terms,
			},
			{
				onSuccess: (responseData) => {
					responseData.urls.forEach((url) => {
						const a = document.createElement('a');
						a.href = url.url;
						a.download = url.title;
						a.click();
					});

					if (!responseData.urls.length) throw new Error('No download url found');

					setDownloadItems(
						downloadItems.map((item) =>
							item.subject.id === currentDownloadItem.subject.id
								? { ...item, status: 'success', downloadUrl: responseData.urls.at(0)?.url }
								: item
						)
					);
				},
				onError: () => {
					setDownloadItems(
						downloadItems.map((item) =>
							item.subject.id === currentDownloadItem.subject.id
								? { ...item, status: 'error' }
								: item
						)
					);
				},
			}
		);
	}, [downloadItems]);

	return (
		<Form {...form}>
			<form onSubmit={form.handleSubmit(onSubmit)} className="flex flex-col gap-20  xs:gap-32">
				<FormField
					name="subjects"
					control={form.control}
					render={() => (
						<FormItem className="flex flex-col gap-1.5">
							<div className="flex flex-col gap-8 xs:gap-10 sm:gap-12">
								<h2 className="text-3xl font-bold xs:text-4xl">1. Izaberi predmete</h2>
								<div className="flex flex-col gap-2 xs:gap-4">
									<h3 className="text-base font-bold uppercase xs:text-lg">
										Obavezni predmeti
									</h3>
									<div className="flex flex-wrap gap-1.5 xs:gap-3">
										{mandatorySubjects.map((subject) => (
											<FormControl key={subject.value}>
												<FormField
													// disabled={!subject.enabled}
													control={form.control}
													name="subjects"
													render={({ field }) => (
														<label
															key={subject.value}
															className={cn(
																'flex max-w-72 cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-solid border-gray-800 px-7 py-3 max-xs:flex-[0.5] xs:basis-1/6 xs:px-9',
																field.value.includes(subject.value) &&
																	'border-primary-yellow bg-primary-yellow pl-6 pr-4 xs:pl-5 xs:pr-3',
																!subject.enabled &&
																	'cursor-not-allowed border-gray-300 text-gray-300'
															)}>
															<span className="whitespace-nowrap text-sm font-bold xs:text-base sm:text-lg">
																{subject.label}
															</span>
															<Checkbox
																className="-my-1 hidden h-6 w-6 rounded-full p-1 data-[state=checked]:block data-[state=checked]:bg-white/40 xs:-my-1.5 xs:h-8 xs:w-8 sm:h-9 sm:w-9 [&_svg]:!h-3 [&_svg]:!w-3 sm:[&_svg]:!h-5 sm:[&_svg]:!w-5"
																checked={field.value?.includes(subject.value)}
																disabled={!subject.enabled}
																onCheckedChange={(checked) => {
																	return checked
																		? field.onChange([
																				...field.value,
																				subject.value,
																			])
																		: field.onChange(
																				field.value?.filter(
																					(value) =>
																						value !==
																						subject.value
																				)
																			);
																}}
															/>
														</label>
													)}
												/>
											</FormControl>
										))}
									</div>
								</div>
								<div className="flex flex-col gap-2 xs:gap-4">
									<h3 className="text-base font-bold uppercase xs:text-lg">
										Izborni predmeti
									</h3>
									<div className="flex flex-wrap gap-1.5 xs:gap-3">
										{optionalSubjects.map((subject) => (
											<FormControl key={subject.value}>
												<FormField
													control={form.control}
													name="subjects"
													render={({ field }) => (
														<label
															key={subject.value}
															className={cn(
																'flex max-w-72 cursor-pointer items-center justify-center gap-2 rounded-2xl border-2 border-solid border-gray-800 px-7 py-3 max-xs:flex-[0.5] xs:basis-1/6 xs:px-9',
																field.value.includes(subject.value) &&
																	'border-primary-yellow bg-primary-yellow pl-6 pr-4 xs:pl-5 xs:pr-3',
																!subject.enabled &&
																	'cursor-not-allowed border-gray-300 text-gray-300'
															)}>
															<span className="whitespace-nowrap text-sm font-bold xs:text-base sm:text-lg">
																{subject.label}
															</span>
															<Checkbox
																className="-my-1 hidden h-6 w-6 rounded-full p-1 data-[state=checked]:block data-[state=checked]:bg-white/40 xs:-my-1.5 xs:h-8 xs:w-8 sm:h-9 sm:w-9 [&_svg]:!h-3 [&_svg]:!w-3 sm:[&_svg]:!h-5 sm:[&_svg]:!w-5"
																checked={field.value?.includes(subject.value)}
																disabled={!subject.enabled}
																onCheckedChange={(checked) => {
																	return checked
																		? field.onChange([
																				...field.value,
																				subject.value,
																			])
																		: field.onChange(
																				field.value?.filter(
																					(value) =>
																						value !==
																						subject.value
																				)
																			);
																}}
															/>
														</label>
													)}
												/>
											</FormControl>
										))}
									</div>
								</div>
							</div>
							<FormMessage />
						</FormItem>
					)}
				/>
				<div className="flex flex-col gap-8">
					<div className="flex flex-wrap gap-4">
						<h2 className="text-3xl font-bold xs:text-4xl">2. Izaberi godine</h2>
						<Button
							type="button"
							size={BtnSizeEnum.Small}
							variant={
								areAllTermsAndYearsSelected ? BtnVariantEnum.Primary : BtnVariantEnum.Outline
							}
							color={BtnColorEnum.Dark}
							onClick={() => {
								form.setValue(
									'years',
									areAllTermsAndYearsSelected
										? []
										: availableYears
												.filter((year) => year.enabled)
												.map((year) => year.value)
								);
								form.setValue(
									'terms',
									areAllTermsAndYearsSelected
										? []
										: availableTerms
												.filter((term) => term.enabled)
												.map((term) => term.value)
								);
							}}>
							<span>Odaberi sve</span>
							{areAllTermsAndYearsSelected && <IconCheck />}
						</Button>
					</div>
					<div className="flex flex-col gap-10 sm:gap-12">
						<FormField
							name="years"
							control={form.control}
							render={() => (
								<FormItem className="flex flex-col gap-1.5">
									<div className="flex flex-wrap gap-x-8 gap-y-4 sm:gap-x-12">
										{availableYears.map((year) => (
											<FormControl key={year.value}>
												<FormField
													control={form.control}
													name="years"
													render={({ field }) => (
														<label
															className={cn(
																'flex min-w-28 basis-[12%] items-center gap-1.5',
																!year.enabled &&
																	'cursor-not-allowed text-gray-200'
															)}
															key={year.value}
															htmlFor={year.value.toString()}>
															<Checkbox
																disabled={!year.enabled}
																checked={field.value?.includes(year.value)}
																onCheckedChange={(checked) => {
																	return checked
																		? field.onChange([
																				...field.value,
																				year.value,
																			])
																		: field.onChange(
																				field.value?.filter(
																					(value) =>
																						value !== year.value
																				)
																			);
																}}
															/>
															<span className="whitespace-nowrap text-lg font-bold">
																{year.label}
															</span>
														</label>
													)}
												/>
											</FormControl>
										))}
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
						<FormField
							name="terms"
							control={form.control}
							render={() => (
								<FormItem className="flex flex-col gap-1.5">
									<div className="flex flex-wrap gap-x-8 gap-y-4 sm:gap-x-12 sm:gap-y-8">
										{availableTerms.map((term) => (
											<FormControl key={term.value}>
												<FormField
													control={form.control}
													name="terms"
													render={({ field }) => (
														<label
															key={term.value}
															className={cn(
																'flex min-w-28 items-center gap-1.5',
																!term.enabled &&
																	'cursor-not-allowed text-gray-300'
															)}
															htmlFor={term.value.toString()}>
															<Checkbox
																disabled={!term.enabled}
																checked={field.value?.includes(term.value)}
																onCheckedChange={(checked) => {
																	return checked
																		? field.onChange([
																				...field.value,
																				term.value,
																			])
																		: field.onChange(
																				field.value?.filter(
																					(value) =>
																						value !== term.value
																				)
																			);
																}}
															/>
															<span className="whitespace-nowrap text-lg font-bold">
																{term.label}
															</span>
														</label>
													)}
												/>
											</FormControl>
										))}
									</div>
									<FormMessage />
								</FormItem>
							)}
						/>
					</div>
				</div>
				<MaturaDownloadItems items={downloadItems} />
				<div className="flex w-full flex-col items-center justify-center gap-2">
					<Button
						disabled={isPending}
						variant={BtnVariantEnum.Primary}
						size={BtnSizeEnum.Medium}
						color={BtnColorEnum.Blue}
						type="submit">
						Preuzmi mature{' '}
						{isPending ? <IconLoader2 className="animate-spin" /> : <IconDownload />}
					</Button>
				</div>
			</form>
		</Form>
	);
}

export default MaturaDownloaderForm;
