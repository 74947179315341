import type { SellingPlanPriceAdjustmentValue, ShopifySellingPlan } from '@features/api/shopify/types';

function mapCurrancyCodeToSign(currencyCode: string | undefined) {
	switch (currencyCode) {
		case 'USD':
			return '$';
		case 'EUR':
			return '€';
		case 'HRK':
			return 'kn';
		default:
			return '€';
	}
}

type formatMoneyArgs = {
	amount: string | number | undefined | null;
	currencyCode?: string;
};

export function formatMoney({ amount, currencyCode = undefined }: formatMoneyArgs) {
	if (amount === undefined || amount === null) return null;

	const currencySign = mapCurrancyCodeToSign(currencyCode);
	let formattedAmount;

	if (typeof amount === 'string') {
		formattedAmount = parseFloat(amount) % 1 === 0 ? parseInt(amount, 10) : amount.replace(',', '.');
	}
	if (typeof amount === 'number')
		formattedAmount = amount % 1 === 0 ? amount.toFixed(0) : amount.toFixed(2).replace(',', '.');

	if (formattedAmount && formattedAmount.toString().includes('.')) {
		formattedAmount = parseFloat(formattedAmount.toString()).toFixed(2).replace(',', '.');
	}

	return `${formattedAmount} ${currencySign}`;
}

type formatMoneyWithAdjustmentArgs = formatMoneyArgs & {
	adjustmentValue: SellingPlanPriceAdjustmentValue | undefined;
};

export function formatMoneyWithAdjustment({
	amount,
	adjustmentValue,
	currencyCode = undefined,
}: formatMoneyWithAdjustmentArgs) {
	if (!adjustmentValue || !amount) return formatMoney({ amount, currencyCode });

	let adjustedAmount;

	switch (adjustmentValue['__typename']) {
		case 'SellingPlanFixedAmountPriceAdjustment':
			adjustedAmount =
				parseFloat(amount.toString()) - parseFloat(adjustmentValue.adjustmentAmount.amount);

			return formatMoney({ amount: adjustedAmount, currencyCode });

		case 'SellingPlanPercentagePriceAdjustment':
			adjustedAmount = parseFloat(amount.toString()) * (1 - adjustmentValue.adjustmentPercentage / 100);

			return formatMoney({ amount: adjustedAmount, currencyCode });

		case 'SellingPlanFixedPriceAdjustment':
			adjustedAmount = parseFloat(adjustmentValue.price.amount);

			return formatMoney({ amount: adjustedAmount, currencyCode: adjustmentValue.price.currencyCode });
	}
}

export function formatAdjustmentValue(adjustmentValue: SellingPlanPriceAdjustmentValue | undefined) {
	if (!adjustmentValue) return undefined;

	switch (adjustmentValue['__typename']) {
		case 'SellingPlanFixedAmountPriceAdjustment':
			return formatMoney(adjustmentValue.adjustmentAmount);
		case 'SellingPlanPercentagePriceAdjustment':
			return `${adjustmentValue.adjustmentPercentage}%`;
		case 'SellingPlanFixedPriceAdjustment':
			return formatMoney(adjustmentValue.price);
	}
}

export function formatSellingPlanRecurrencePeriodLabel(sellingPlan: ShopifySellingPlan | undefined) {
	if (sellingPlan?.name.includes('year')) return 'godišnje';
	if (sellingPlan?.name.includes('month')) return 'mjesečno';
	if (sellingPlan?.name.includes('week')) return 'tjedno';
}

export function formatSellingPlanRecurrencePeriodDiscountLabel(sellingPlan: ShopifySellingPlan | undefined) {
	if (sellingPlan?.name.includes('year')) return 'prvu godinu';
	if (sellingPlan?.name.includes('month')) return 'prvi mjesec';
	if (sellingPlan?.name.includes('week')) return 'prvi tjedan';
}
