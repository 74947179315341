'use client';
import { type SellingPlanGroup } from '@features/api/shopify/types';
import { cn } from '@features/shared/utils/utils';
import { formatAdjustmentValue } from '@features/shared/utils/formatMoney';
import type { ReactNode } from 'react';
import { useCartStore } from '@features/webshop/cart/CartStore';

type SubscriptionPlanToggleButtonProps = {
	sellingPlanGroup: SellingPlanGroup;
	children: ReactNode;
};

function SubscriptionPlanToggleButton({ sellingPlanGroup, children }: SubscriptionPlanToggleButtonProps) {
	const selectedSellingPlan = useCartStore((state) => state.selectedSellingPlan);
	const setSelectedSellingPlan = useCartStore((state) => state.setSelectedSellingPlan);

	const sellingPlan = sellingPlanGroup.sellingPlans[0];
	const adjustment = sellingPlan?.priceAdjustments[0];
	const isFixedValue = adjustment?.adjustmentValue?.__typename === 'SellingPlanFixedPriceAdjustment';
	const adjustmentValue = isFixedValue
		? null
		: formatAdjustmentValue(sellingPlan?.priceAdjustments[0]?.adjustmentValue);

	const isSelected = selectedSellingPlan?.id === sellingPlan?.id;

	return (
		<div
			className={cn(
				'relative flex cursor-pointer items-center justify-center gap-2 border border-solid border-white px-2.5 py-4 first:rounded-l-2xl last:rounded-r-2xl max-sm:flex-1 xs:px-4 sm:px-5 ',
				isSelected && 'bg-white text-primary-blue'
			)}
			onClick={() => setSelectedSellingPlan(sellingPlan)}>
			{children}
			{adjustmentValue && (
				<div className="absolute left-1/2 top-full flex -translate-y-1/2 items-start justify-start">
					<div
						className={cn(
							'font-normal flex -translate-x-1/2 items-center justify-center rounded-2xl bg-gray-800 px-4 py-1 text-[14px] text-white',
							isSelected && 'bg-primary-yellow text-black'
						)}>
						{adjustmentValue} <span className="hidden md:inline">off</span>
					</div>
				</div>
			)}
		</div>
	);
}

export default SubscriptionPlanToggleButton;
