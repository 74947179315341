'use client';

import {
	type ProductList,
	type Product,
	BtnColorEnum,
	BtnSizeEnum,
	BtnVariantEnum,
} from '@features/api/client';
import { type Cart, type Product as ShopifyProductType } from '../../../../../api/shopify/types';
import ToggleSubscriptionCartItemButton from './SubscriptionAddToCartButton';
import {
	formatSellingPlanRecurrencePeriodDiscountLabel,
	formatSellingPlanRecurrencePeriodLabel,
} from '@features/shared/utils/formatMoney';
import { useRouter } from 'next/navigation';
import { Button } from '@features/webshop/shared/ui/Button';
import useUpdateCartOnChange from '@features/webshop/cart/hooks/useUpdateCartOnChange';
import { cn } from '@features/shared/utils/utils';
import { apiClient } from '@features/api/api.client';
import useGetCartWithTotals from '@features/webshop/cart/hooks/useCartTotals';
import { useCartStore } from '@features/webshop/cart/CartStore';
import { IconTag } from '@tabler/icons-react';
import DiscountCodeRibbon from '@features/webshop/landing-pages/ui/parts/DiscountCodeRibbon/DiscountCodeRibbon';

type SubscriptionPriceInfoProps = {
	shopifyProduct: ShopifyProductType;
	cmsProduct: Product | ProductList | undefined;
	initialCart: Cart;
};

function SubscriptionPriceInfo({ shopifyProduct, cmsProduct, initialCart }: SubscriptionPriceInfoProps) {
	const router = useRouter();

	const selectedVariantIds = useCartStore((state) => state.selectedVariantIds);
	const selectedSellingPlan = useCartStore((state) => state.selectedSellingPlan);

	const selectedVariant = shopifyProduct.variants.find((variant) => variant.id === selectedVariantIds[0]);

	const cartQuery = apiClient.cart.useGetCart({ initialData: initialCart });
	const { data: cart } = cartQuery;
	const { isCartUpdating, isCartDiscounted, priceBeforeDiscount, discountCodes } = useGetCartWithTotals({
		cartQuery,
	});
	useUpdateCartOnChange({ cart });

	if (!cmsProduct || !shopifyProduct) return null;

	return (
		<div className="flex h-full w-full flex-col justify-between gap-12 xs:gap-16 sm:gap-20 md:gap-24">
			<div className="flex flex-col gap-2 xs:gap-4">
				<div className="text-base font-medium sm:text-xl">Odaberi razred</div>
				<div className="flex gap-4 max-xs:w-full xs:gap-5">
					{shopifyProduct.variants.map((variant, index) => (
						<ToggleSubscriptionCartItemButton
							shopifyProductVariantId={variant.id}
							key={variant.id}>
							<span className="translate-x-[2px] text-xl font-black xs:text-2xl">
								{index + 1}.
							</span>
						</ToggleSubscriptionCartItemButton>
					))}
				</div>
			</div>
			<div className="flex flex-col gap-4 self-stretch">
				<div className="flex flex-col gap-2 self-stretch xs:gap-4">
					<div className="flex flex-col">
						{isCartDiscounted && (
							<span className="flex items-center text-base font-light sm:text-lg">
								umjesto <span className="ml-1 mr-1 font-medium"> {priceBeforeDiscount} </span>{' '}
								{formatSellingPlanRecurrencePeriodDiscountLabel(selectedSellingPlan)} plaćaš
							</span>
						)}
						<div className={cn('flex items-end gap-2', isCartUpdating && 'opacity-70')}>
							<span className="text-6xl font-black leading-none md:text-[82px]">
								{cart?.cost.totalAmount.amount}
							</span>

							<span className="flex flex-col justify-center">
								<span className="text-3xl font-extrabold sm:text-4xl md:text-5xl">
									{cart?.cost.totalAmount.currencyCode}
								</span>
								<span className="text-md text-base font-bold md:text-lg">
									/{formatSellingPlanRecurrencePeriodLabel(selectedSellingPlan)}
								</span>
							</span>
						</div>
					</div>
					{discountCodes.map((code) => (
						<DiscountCodeRibbon key={code.code} discountCode={code} />
					))}
				</div>

				<Button
					size={BtnSizeEnum.Large}
					color={BtnColorEnum.Red}
					variant={BtnVariantEnum.Primary}
					disabled={!cart}
					className="w-full"
					onClick={() => {
						if (!cart) return;

						router.push(cart.checkoutUrl);
					}}>
					<span className="flex items-center gap-0.5 xs:gap-2">
						<span>Odaberi</span>
						{selectedVariant && (
							<span className="text-md min-w-[100px] text-base font-regular md:text-xl">
								({selectedVariant?.title})
							</span>
						)}
					</span>
				</Button>
			</div>
		</div>
	);
}

export default SubscriptionPriceInfo;
